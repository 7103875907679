html {
    scroll-behavior: smooth;
}

.clear {
    clear: both;
}

.pageTitle {
    color: #333;
    font: 700 37px/37px 'Chivo', sans-serif;
    padding: 41px 0 40px;
}

.contact__itemTitle {
    color: #333;
    font: 400 20px/150% 'Chivo', sans-serif;

}

.contact__link {
    color: #333;
    text-decoration: none;
    font: 700 20px/150% 'Chivo', sans-serif;
}

.header {
    background: hsl(104, 19%, 68%);
    box-shadow: 0 0 12px 2px hsl(104, 19%, 20%);
}

.headerContent {
    margin: 0 auto;
    max-width: 800px;
}

.introductionPicture {
    float: left;
    display: inline-block;
    margin-right: 40px;
}

.mainContent {
    margin: 0 auto;
    max-width: 800px;
}

.mainNavItem {
    display: inline-block;
    padding-top: 25px;
}

.mainNavLink {
    color: hsla(104, 19%, 20%, 1);
    font: 700 23px/220% 'Chivo', sans-serif;
    margin-right: 40px;
    text-decoration: none;
}

.mainNavScrolled {
    background: hsla(104, 19%, 68%, 1);
    box-shadow: 0 0 12px 2px hsl(104, 19%, 20%);
    left: 0;
    position: fixed;
    text-align: center;
    top: 0;
    width: 100%;
    transition: top .210s ease-out;
}

.mainNavScrolled--Hidden {
    top: -250px;
    overflow: hidden;
    transition: top .210s ease-in;
}

.mainNavScrolledItem {
    display: inline-block;
}

.mainNavScrolledLink {
    color: hsla(104, 19%, 20%, 1);
    font: 700 23px/290% 'Chivo', sans-serif;
    text-decoration: none;
}

.mainNavScrolledItem:not(:last-child) {
    margin-right: 40px;
}

@media (max-width: 820px) {
    .introductionPicture {
        width: 160px;
    }

    .pageTitle {
        font: 700 28px/28px 'Chivo', sans-serif;
        padding: 32px 0 28px;
    }

    .headerContent {
        max-width: 650px;
    }

    .contact {
        font: 400 16px/150% 'Chivo', sans-serif;
    }

    .mainContent {
        max-width: 650px;
    }

    .mainNavItem {
        padding-top: 18px;
    }

    .mainNavLink {
        color: hsla(104, 19%, 20%, 1);
        font: 700 20px/220% 'Chivo', sans-serif;
        margin-right: 40px;
        text-decoration: none;
    }

    .mainNavScrolledLink {
        color: hsla(104, 19%, 20%, 1);
        font: 700 20px/290% 'Chivo', sans-serif;
        text-decoration: none;
    }
}

@media (max-width: 680px) {
    .introductionPicture {
        display: none;
    }

    .pageTitle {
        padding-left: 20px;
    }

    .contact {
        padding-left: 20px
    }

    .mainNav {
        padding-left: 20px;
        padding-bottom: 25px;
    }

    .headerContent {
        width: 100%;
        max-width: 100%;
    }

    .contact__itemTitle {
        display: none;
    }

    .mainNavItem {
        display: block;
    }

    .mainNavLink {
        font: 700 18px/120% 'Chivo', sans-serif;
    }

    .mainNavScrolledItem--Hideable {
        display: none;
    }

    .mainContent {
        padding: 20px;
    }
}

.contentSection:not(:first-child) {
    border-top: 1px solid hsla(104, 19%, 68%, .75);
    margin-top: 28px;
}

.content {
    color: #333;
    font: normal 20px/170% 'Source Serif Pro', serif;
    margin-bottom: 18px;
}

.content__link {
    color: #1780e8;
    text-decoration: none;
}

.sectionTitle {
    color: #333;
    font: 700 30px/300% 'Chivo', sans-serif;
}

.sectionSubtitle {
    color: #333;
    font: 700 20px/240% 'Source Serif Pro', serif;
}

.resumeContent {
    color: #333;
    font: normal 20px/170% 'Source Serif Pro', serif;
    margin-bottom: 18px;
}

.content__list {
    list-style-type: disc;
    padding-left: 32px;
}

.content__listItem {
    color: #333;
    font: normal 20px/150% 'Source Serif Pro', serif;
}

.publicationTitle {
    font-style: italic;
}

.publicationListImage {
    float: left;
}

.publicationListItemLink {
    color: #1780e8;
    text-decoration: none;
    font: normal 22px/160% 'Source Code Pro', serif;
}

.publicationListItemLink--NoResource {
    color: #333;
}

.publicationListItem {
    margin-top: 15px;
}

.publicationListDetail {
    display: block;
    color: #333;
    font: normal 16px/110% 'Source Code Pro', serif;
}

@media (max-width: 820px) {
    .content {
        font: normal 19px/170% 'Source Serif Pro', serif;
    }

    .sectionTitle {
        font: 700 26px/300% 'Chivo', sans-serif;
    }

    .sectionSubtitle {
        font: 700 21px/240% 'Source Serif Pro', serif;
    }

    .resumeContent {
        font: normal 19px/170% 'Source Serif Pro', serif;
        margin-bottom: 16px;
    }

    .content__listItem {
        font: normal 18px/150% 'Source Serif Pro', serif;
    }

    .publicationListItemLink {
        font: normal 20px/160% 'Source Code Pro', serif;
    }

    .publicationListItem {
        margin-top: 12px;
    }

    .publicationListDetail {
        font: normal 14px/110% 'Source Code Pro', serif;
    }
}

@media (max-width: 680px) {
    .content {
        font: normal 17px/170% 'Source Serif Pro', serif;
    }

    .sectionTitle {
        font: 700 24px/300% 'Chivo', sans-serif;
    }

    .sectionSubtitle {
        font: 700 19px/240% 'Source Serif Pro', serif;
    }

    .resumeContent {
        font: normal 16px/170% 'Source Serif Pro', serif;
        margin-bottom: 14px;
    }

    .content__listItem {
        font: normal 16px/150% 'Source Serif Pro', serif;
    }

    .publicationListItemLink {
        font: normal 16px/160% 'Source Code Pro', serif;
    }

    .publicationListItem {
        margin-top: 12px;
    }

    .publicationListDetail {
        font: normal 14px/110% 'Source Code Pro', serif;
    }
}

.footer {
    height: 80px;
}